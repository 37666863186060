import React from "react";
import "../styles/SolutionPage.scss";

function Landing() {
    return(
        <>
            <div className="solution-page-container">
                <div className="text-contents">
                    <h1>AI SOLUTIONS FOR CONSTRUCTION</h1>
                    <h2>
                        AI in contruction: <br/>
                        towards future construction through computer vision
                    </h2>
                    <p className="solution-description">
                        Use AI vision to optimize the whole process: Worker safety detection, People counting, Worker
                        anonymization for privacy
                    </p>
                    <br/>
                    <h2>
                        Our key features
                    </h2>
                    <li>Optimized for Edge Computing</li>
                    <li>Robust performance - Complex background, Small to large objects</li>
                </div>

                <div>
                <img src="/images/img_3.png"
                         alt="" className="main-image"/>
                </div>
            </div>
        </>
    )
}

function UseCases() {
    return(
        <>
            <div className="use-cases-container">
                <h2>AI Use Cases in Construction</h2>
                <p className="use-cases-description">
                    Explore how AI is transforming the construction industry through innovative solutions.
                </p>

                <div className="use-cases-grid">
                    <div className="use-case">
                        <img src="/images/img_4.png" alt="Worker Safety" className="use-case-image"/>
                        <h3>Worker Safety Monitoring</h3>
                        <p>
                            Ensure the safety of workers on-site by detecting potential risks in real-time.
                        </p>
                    </div>

                    <div className="use-case">
                        <img src="/images/img_5.png" alt="People Counting" className="use-case-image"/>
                        <h3>People Counting</h3>
                        <p>
                            Track the number of workers on-site to optimize workforce management and resource
                            allocation.
                        </p>
                    </div>

                    <div className="use-case">
                        <img src="/images/img_6.png" alt="Privacy Protection" className="use-case-image"/>
                        <h3>Privacy Protection</h3>
                        <p>
                            Anonymize worker identities in video feeds to ensure privacy while maintaining compliance
                            with regulations.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}


function FAQ() {
    return (
        <div className="faq-container">
            <h1>FAQ about our Solutions for Construction</h1>
            <div className="faq-box">
                <h2>What are examples of AI in construction?</h2>
                <p>AI in construction includes worker safety detection, real-time equipment monitoring,</p>
                <p>
                    predictive maintenance, and automating repetitive tasks like scheduling and material tracking.
                </p>
            </div>
            <div className="faq-box">
                <h2>How does AI improve worker safety?</h2>
                <p>
                    AI improves worker safety by detecting potential risks, ensuring compliance with safety measures,
                </p>
                <p>
                    and sending real-time alerts for hazardous situations.
                </p>
            </div>
            <div className="faq-box">
                <h2>Is AI cost-effective for small construction companies?</h2>
                <p>
                    Yes,
                </p>
                <p>AI can be cost-effective even for small construction companies by optimizing resource
                    allocation,</p>
                <p>
                    reducing waste, and enhancing efficiency.
                </p>
            </div>
        </div>)
}


function SolutionPage() {
    return (
        <>
            <Landing/>
            <UseCases/>
            <FAQ/>
        </>
    );
}

export default SolutionPage;
